<template>
    <div>   
		<div class="card border-primary round h-100">
			<div class="card-body p-3">
				<h4 class="text-center"><i class="fa fa-cart-arrow-down"></i> &nbsp; Transaksi Pembelian</h4>
				<hr />
				<ul class="nav nav-pills w-100" id="pills-tab" role="tablist">
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1" @click="get_transactionList(1)">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm active" data-toggle="tab" href="#menunggu-pembayaran-tab" role="tab">
							<div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_transaksi_count[1]">{{ jumlah_transaksi_count[1] }}</span></div>
							<i class="far fa-clock fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center" style="height: 40px; overflow: hidden;">Menunggu Pembayaran</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1" @click="get_transactionList(2)">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#sudah-dibayar-tab" role="tab">
							<div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_transaksi_count[2]">{{ jumlah_transaksi_count[2] }}</span></div>
							<i class="fa fa-cash-register fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center" style="height: 40px; overflow: hidden;">Sudah Dibayar</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1" @click="get_transactionList(3)">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#pesanan-diproses-tab" role="tab">
							<div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_transaksi_count[3]">{{ jumlah_transaksi_count[3] }}</span></div>
							<i class="fa fa-cog fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center" style="height: 40px; overflow: hidden;">Pesanan Diproses</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1" @click="get_transactionList(4)">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#pesanan-dikirim-tab" role="tab">
							<div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_transaksi_count[4]">{{ jumlah_transaksi_count[4] }}</span></div>
							<i class="fa fa-truck fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center" style="height: 40px; overflow: hidden;">Pesanan Dikirim</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 px-2 pt-2 pb-1" @click="get_transactionList(5)">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#pesanan-selesai-tab" role="tab">
							<div class="d-inline-flex justify-content-center align-items-center">
								<!-- <div class="position-relative ml-2"><span class="badge badge-float-3 badge-pill badge-danger badge-lg" v-if="jumlah_transaksi_count[5]">{{ jumlah_transaksi_count[5] }}</span></div> -->
								<i class="fa fa-check-circle fa-2x mt-1"></i>
								<h6 class="mb-0 ml-2">Pesanan Selesai</h6>
							</div>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 px-2 pt-2 pb-1" @click="get_transactionList(6)">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#pesanan-dibatalkan-tab" role="tab">
							<!-- <div class="position-relative ml-2"><span class="badge badge-float-3 badge-pill badge-danger badge-lg" v-if="jumlah_transaksi_count[6]">{{ jumlah_transaksi_count[6] }}</span></div> -->
							<div class="d-inline-flex justify-content-center align-items-center">
								<i class="fa fa-times-circle fa-2x mt-1"></i>
								<h6 class="mb-0 ml-2">Pesanan Dibatalkan</h6>
							</div>
						</a>
					</li>
				</ul>
				<div class="tab-content border rounded-lg shadow px-4 py-3 mt-2">
					<div class="tab-pane fade show active px-3 py-2" id="menunggu-pembayaran-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_transaksi <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="transactionList" v-else>
								<div class="col-md-12 mb-3" v-for="(transaksi, index) in list_transaksi" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-md-4 d-lg-block d-md-block d-none mb-1">
													<div class="row align-items-center justify-content-center" v-if="transaksi.images.length == 1">
														<div class="m-1 p-3 border rounded">
															<img v-lazy="productImage(transaksi.images[0])" @error="imageLoadError" class="card-img-top" :alt="transaksi.images[0]" />
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else-if="transaksi.images.length <= 4">
														<div class="col-6 p-0 m-0" v-for="image in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else>
														<div class="col-6 p-0 m-0" v-for="(image, index) in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded" v-if="index < 3">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
															<div class="m-1 p-2 border rounded text-muted text-center h-100" v-if="index == 3">
																<i class="fa fa-plus"></i><br /><small class="d-none d-lg-block">Lainya</small>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-8 col-12 text-muted table-responsive-sm mb-1">
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_invoice }}</td>
													</tr>
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_date_buy | momentFormatID }}</td>
													</tr>
													<tr>
														<td>Total Pembayaran</td>
														<td class="px-2">:</td>
														<td><span class="text-success">{{ transaksi.trans_total_payment | formatCurrency('IDR') }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2">:</td>
														<td><span class="text-info">{{ transaksi.trans_status_desc | capitalize }}</span></td>
													</tr>
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<button type="button" class="btn btn-outline-success w-100 mb-2" data-toggle="modal" data-target="#modal-upload-bukti" @click="prepare_uploadBukti(transaksi.trans_invoice, transaksi.trans_total_payment)">Unggah Bukti Bayar &nbsp;<i class="fa fa-upload"></i></button>
											<button type="button" class="btn btn-outline-info w-100 mb-2" data-toggle="modal" data-target="#modal-detail-transaksi" @click="get_detailTransaction(transaksi.trans_id, transaksi.trans_status_desc)">Detail &nbsp;<i class="fa fa-search-plus"></i></button>
											<button type="button" class="btn btn-outline-danger w-100" data-toggle="modal" data-target="#modal-pesanan-dibatalkan" @click="prepare_pesananDibatalkan(transaksi.trans_invoice)">Batalkan &nbsp;<i class="fa fa-trash"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="sudah-dibayar-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_transaksi <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="transactionList" v-else>
								<div class="col-md-12 mb-3" v-for="(transaksi, index) in list_transaksi" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-md-4 d-lg-block d-md-block d-none mb-1">
													<div class="row align-items-center justify-content-center" v-if="transaksi.images.length == 1">
														<div class="m-1 p-3 border rounded">
															<img v-lazy="productImage(transaksi.images[0])" @error="imageLoadError" class="card-img-top" :alt="transaksi.images[0]" />
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else-if="transaksi.images.length <= 4">
														<div class="col-6 p-0 m-0" v-for="image in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else>
														<div class="col-6 p-0 m-0" v-for="(image, index) in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded" v-if="index < 3">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
															<div class="m-1 p-2 border rounded text-muted text-center h-100" v-if="index == 3">
																<i class="fa fa-plus"></i><br /><small class="d-none d-lg-block">Lainya</small>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-8 col-12 text-muted table-responsive-sm mb-1">
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_invoice }}</td>
													</tr>
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_date_buy | momentFormatID }}</td>
													</tr>
													<tr>
														<td>Total Pembayaran</td>
														<td class="px-2">:</td>
														<td><span class="text-success">{{ transaksi.trans_total_payment | formatCurrency('IDR') }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2">:</td>
														<td><span class="text-info">{{ transaksi.trans_status_desc | capitalize }}</span></td>
													</tr>
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<button type="button" class="btn btn-outline-info w-100" data-toggle="modal" data-target="#modal-detail-transaksi" @click="get_detailTransaction(transaksi.trans_id, transaksi.trans_status_desc)">Detail &nbsp;<i class="fa fa-search-plus"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="pesanan-diproses-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_transaksi <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="transactionList" v-else>
								<div class="col-md-12 mb-3" v-for="(transaksi, index) in list_transaksi" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-md-4 d-lg-block d-md-block d-none mb-1">
													<div class="row align-items-center justify-content-center" v-if="transaksi.images.length == 1">
														<div class="m-1 p-3 border rounded">
															<img v-lazy="productImage(transaksi.images[0])" @error="imageLoadError" class="card-img-top" :alt="transaksi.images[0]" />
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else-if="transaksi.images.length <= 4">
														<div class="col-6 p-0 m-0" v-for="image in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else>
														<div class="col-6 p-0 m-0" v-for="(image, index) in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded" v-if="index < 3">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
															<div class="m-1 p-2 border rounded text-muted text-center h-100" v-if="index == 3">
																<i class="fa fa-plus"></i><br /><small class="d-none d-lg-block">Lainya</small>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-8 col-12 text-muted table-responsive-sm mb-1">
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_invoice }}</td>
													</tr>
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_date_buy | momentFormatID }}</td>
													</tr>
													<tr>
														<td>Total Pembayaran</td>
														<td class="px-2">:</td>
														<td><span class="text-success">{{ transaksi.trans_total_payment | formatCurrency('IDR') }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2">:</td>
														<td><span class="text-info">{{ transaksi.trans_status_desc | capitalize }}</span></td>
													</tr>
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<button type="button" class="btn btn-outline-info w-100" data-toggle="modal" data-target="#modal-detail-transaksi" @click="get_detailTransaction(transaksi.trans_id, transaksi.trans_status_desc)">Detail &nbsp;<i class="fa fa-search-plus"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="pesanan-dikirim-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_transaksi <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="transactionList" v-else>
								<div class="col-md-12 mb-3" v-for="(transaksi, index) in list_transaksi" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-md-4 d-lg-block d-md-block d-none mb-1">
													<div class="row align-items-center justify-content-center" v-if="transaksi.images.length == 1">
														<div class="m-1 p-3 border rounded">
															<img v-lazy="productImage(transaksi.images[0])" @error="imageLoadError" class="card-img-top" :alt="transaksi.images[0]" />
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else-if="transaksi.images.length <= 4">
														<div class="col-6 p-0 m-0" v-for="image in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else>
														<div class="col-6 p-0 m-0" v-for="(image, index) in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded" v-if="index < 3">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
															<div class="m-1 p-2 border rounded text-muted text-center h-100" v-if="index == 3">
																<i class="fa fa-plus"></i><br /><small class="d-none d-lg-block">Lainya</small>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-8 col-12 text-muted table-responsive-sm mb-1">
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_invoice }}</td>
													</tr>
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_date_buy | momentFormatID }}</td>
													</tr>
													<tr>
														<td>Total Pembayaran</td>
														<td class="px-2">:</td>
														<td><span class="text-success">{{ transaksi.trans_total_payment | formatCurrency('IDR') }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2">:</td>
														<td><span class="text-info">{{ transaksi.trans_status_desc | capitalize }}</span></td>
													</tr>
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<button type="button" class="btn btn-outline-success w-100 mb-2"  data-toggle="modal" data-target="#modal-pesanan-diterima" @click="prepare_pesananDiterima(transaksi.trans_invoice)">Pesanan Diterima &nbsp;<i class="fa fa-check"></i></button>
											<button type="button" class="btn btn-outline-info w-100" data-toggle="modal" data-target="#modal-detail-transaksi" @click="get_detailTransaction(transaksi.trans_id, transaksi.trans_status_desc)">Detail &nbsp;<i class="fa fa-search-plus"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="pesanan-selesai-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_transaksi <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="transactionList" v-else>
								<div class="col-md-12 mb-3" v-for="(transaksi, index) in list_transaksi" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-md-4 d-lg-block d-md-block d-none mb-1">
													<div class="row align-items-center justify-content-center" v-if="transaksi.images.length == 1">
														<div class="m-1 p-3 border rounded">
															<img v-lazy="productImage(transaksi.images[0])" @error="imageLoadError" class="card-img-top" :alt="transaksi.images[0]" />
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else-if="transaksi.images.length <= 4">
														<div class="col-6 p-0 m-0" v-for="image in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else>
														<div class="col-6 p-0 m-0" v-for="(image, index) in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded" v-if="index < 3">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
															<div class="m-1 p-2 border rounded text-muted text-center h-100" v-if="index == 3">
																<i class="fa fa-plus"></i><br /><small class="d-none d-lg-block">Lainya</small>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-8 col-12 text-muted table-responsive-sm mb-1">
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_invoice }}</td>
													</tr>
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_date_buy | momentFormatID }}</td>
													</tr>
													<tr>
														<td>Total Pembayaran</td>
														<td class="px-2">:</td>
														<td><span class="text-success">{{ transaksi.trans_total_payment | formatCurrency('IDR') }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2">:</td>
														<td><span class="text-info">{{ transaksi.trans_status_desc | capitalize }}</span></td>
													</tr>
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<router-link to="/member/review" class="btn btn-outline-success w-100 mb-2">Berikan Ulasan &nbsp;<i class="fa fa-pen"></i></router-link>
											<button type="button" class="btn btn-outline-info w-100" data-toggle="modal" data-target="#modal-detail-transaksi" @click="get_detailTransaction(transaksi.trans_id, transaksi.trans_status_desc)">Detail &nbsp;<i class="fa fa-search-plus"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="pesanan-dibatalkan-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_transaksi <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="transactionList" v-else>
								<div class="col-md-12 mb-3" v-for="(transaksi, index) in list_transaksi" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-md-4 d-lg-block d-md-block d-none mb-1">
													<div class="row align-items-center justify-content-center" v-if="transaksi.images.length == 1">
														<div class="m-1 p-3 border rounded">
															<img v-lazy="productImage(transaksi.images[0])" @error="imageLoadError" class="card-img-top" :alt="transaksi.images[0]" />
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else-if="transaksi.images.length <= 4">
														<div class="col-6 p-0 m-0" v-for="image in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
														</div>
													</div>
													<div class="row align-items-center justify-content-center" v-else>
														<div class="col-6 p-0 m-0" v-for="(image, index) in transaksi.images" :key="image">
															<div class="m-1 p-2 border rounded" v-if="index < 3">
																<img v-lazy="productImage(image)" @error="imageLoadError" class="card-img-top" :alt="image" />
															</div>
															<div class="m-1 p-2 border rounded text-muted text-center h-100" v-if="index == 3">
																<i class="fa fa-plus"></i><br /><small class="d-none d-lg-block">Lainya</small>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-8 col-12 text-muted table-responsive-sm mb-1">
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_invoice }}</td>
													</tr>
													<tr>
														<td colspan="3" class="text-dark">{{ transaksi.trans_date_buy | momentFormatID }}</td>
													</tr>
													<tr>
														<td>Total Pembayaran</td>
														<td class="px-2">:</td>
														<td><span class="text-success">{{ transaksi.trans_total_payment | formatCurrency('IDR') }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2">:</td>
														<td><span class="text-info">{{ transaksi.trans_status_desc | capitalize }}</span></td>
													</tr>
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<button type="button" class="btn btn-outline-info w-100" data-toggle="modal" data-target="#modal-detail-transaksi" @click="get_detailTransaction(transaksi.trans_id, transaksi.trans_status_desc)">Detail &nbsp;<i class="fa fa-search-plus"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 w-100 text-center mt-1">
							<hr />
							<b-pagination aria-controls="transactionList" v-model="current_page" :per-page="per_page" :total-rows="jumlah_transaksi" first-number last-number align="center" size="md"></b-pagination>
						</div>
					</div>
				</div>
				<!-- Modal detail transaksi -->
				<div class="modal fade" id="modal-detail-transaksi" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLongTitle">Detail Pembelian</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<div class="modal-body px-4">
								<div class="text-center my-3" v-if="is_refreshDetail">
									<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
								</div>
								<div class="container" v-else>
									<div class="row">
										<div class="col-md-12 max-content-user customScroolBar mb-2">
											<h6>Produk:</h6>
											<div class="row d-flex align-items-center w-100 border m-0 p-3 round mb-2" v-for="product in detail_barang_transaksi" :key="product.prod_id">
												<div class="col-md-3 text-center">
													<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top w-75" :alt="image" />
												</div>
												<div class="col-md-9 text-left">
													<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none">
														<h6 class="card-text text-secondary mb-0">{{ product.prod_name | capitalize }}</h6>
														<p class="text-muted mb-0">{{ product.prod_summary | str_limit(50) }}</p>
													</router-link>
													<div class="d-flex justify-content-start align-content-center mb-1" v-if="product.detail_discount && product.detail_discount != 0">
														<p class="text-success mb-0">{{ discountedPrice(product.detail_price, product.detail_discount) | formatCurrency('IDR') }}</p>
														<p class="bg-danger text-white round px-2 ml-3 mb-0">{{ product.detail_discount }}%</p> 
														<strike class="text-muted pl-1">{{ product.detail_price | formatCurrency('IDR') }}</strike>
													</div>
													<p class="text-success mb-0" v-else>{{ product.detail_price | formatCurrency('IDR') }}</p>
													<p class="text-muted mb-0">Total Pembelian : {{ product.detail_quantity }} pcs</p>
												</div>
											</div>
										</div>
										<div class="col-md-12 mb-2">
											<h6>Informasi Pembelian:</h6>
											<div class="row d-flex align-items-center w-100 border border-info m-0 p-2 round mb-2">
												<div class="col-12 table-responsive-sm">
													<tr>
														<td class="py-1" width="25%">Status</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1 text-info">{{ status_transaksi | capitalize }}</td>
													</tr>
													<tr>
														<td class="py-1" width="25%">Invoice</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1">{{ detail_info_transaksi.trans_invoice }}</td>
													</tr>
													<tr>
														<td class="py-1" width="25%">Tanggal Pembelian</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1">{{ detail_info_transaksi.trans_date_buy | momentFormatID }}</td>
													</tr>
													<tr>
														<td class="py-1" width="25%">Pengiriman</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1">{{ detail_info_transaksi.trans_carrier_code | uppercase }} ({{ detail_info_transaksi.trans_carrier_pc }}) | {{ detail_info_transaksi.trans_carrier_name }} <span v-if="detail_info_transaksi.trans_carrier_etd != '' && detail_info_transaksi.trans_carrier_etd != 'Tidak diketahui'">| Estimasi sampai: {{ detail_info_transaksi.trans_carrier_etd }} hari</span></td>
													</tr>
													<tr v-if="detail_info_transaksi.trans_status == 4 && detail_info_transaksi.trans_status < 6">
														<td class="py-1" width="25%">Tanggal Dikirim</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1">{{ detail_info_transaksi.trans_shipment_date | momentFormatID }}</td>
													</tr>
													<tr v-if="detail_info_transaksi.trans_status == 4 && detail_info_transaksi.trans_status < 6">
														<td class="py-1" width="25%">No. Resi (Pengiriman)</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1">{{ detail_info_transaksi.trans_no_shipment }}</td>
													</tr>
													<tr v-if="detail_info_transaksi.trans_status > 1 && detail_info_transaksi.trans_status < 6">
														<td class="py-1" width="25%">Total Pembayaran</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1 text-success">{{ detail_info_transaksi.trans_total_payment | formatCurrency('IDR') }} <button class="btn btn-success btn-sm disabled ml-2">Lunas! &nbsp;<i class="fa fa-check-circle"></i></button></td>
													</tr>
													<tr v-if="account_type != 'personal' && detail_info_transaksi.trans_nomor_npwp">
														<td class="py-1" width="25%">Nomor NPWP</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1">{{ detail_info_transaksi.trans_nomor_npwp }}</td>
													</tr>
													<tr v-if="account_type != 'personal' && detail_info_transaksi.trans_nama_npwp">
														<td class="py-1" width="25%">Nama NPWP</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1">{{ detail_info_transaksi.trans_nama_npwp }}</td>
													</tr>
													<tr>
														<td class="py-1" width="25%">Catatan</td>
														<td class="py-1 px-2">:</td>
														<td class="py-1">{{ detail_info_transaksi.trans_note }}</td>
													</tr>
												</div>
											</div>
										</div>
										<div class="col-md-12" v-if="detail_info_transaksi.trans_status <= 1">
											<h6>Pembayaran</h6>
											<div class="row d-flex align-items-center w-100 border border-success m-0 p-3 round mb-2">
												<div class="col-md-8 table-responsive-sm ">
													<div class="row d-flex align-items-center">
														<div class="col-md-3">
															<div class="p-1">
																<img src="@/assets/images/bank-bca.png" @error="imageLoadError" class="card-img-top w-100 shadow" alt="bank BCA" />
															</div>
														</div>
														<div class="col-md-8">
															<p class="mb-0">BCA KCP Kelapa Gading</p>
															<p class="mb-0">7480639477</p>
															<p class="mb-0">a.n. PT. Putra Perdana Sentosa</p>
														</div>
														<div class="col-12">
															<hr />
														</div>
														<div class="col-md-3">
															<div class="p-1">
																<img src="@/assets/images/bank-bni.png" @error="imageLoadError" class="card-img-top w-100 shadow" alt="bank BNI" />
															</div>
														</div>
														<div class="col-md-8">
															<p class="mb-0">BNI</p>
															<p class="mb-0">7480639470</p>
															<p class="mb-0">a.n. Putra Perdana Sentosa</p>
														</div>
													</div>
												</div>
												<div class="col-md-4 d-flex align-items-center text-cnter">
													<div class="border border-success rounded w-100 p-4 shadow-lg">
														<h6 class="mb-0 text-muted">Total Pembayaran :</h6>
														<h5 class="mb-0 text-success">{{ detail_info_transaksi.trans_total_payment | formatCurrency('IDR') }}</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Modal upload bukti bayar -->
				<div class="modal fade" id="modal-upload-bukti" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLongTitle">Unggah Bukti Bayar</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<form @submit.prevent="upload_buktiBayar" enctype='multipart/form-data'>
								<div class="modal-body px-4">
									<div class="text-center my-3" v-if="is_refreshDetail">
										<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</div>
									<div class="container px-4 text-muted" v-else>
										<tr>
											<td>Nomor Invoice</td>
											<td class="px-2"> : </td>
											<td><span class="text-dark h6">{{ invoice_number }}</span></td>
										</tr>
										<label class="mt-2 mb-0">Nominal Pembayaran</label>
										<div class="form align-items-center mb-3">
											<div class="input-group">
												<div class="input-group-prepend">
													<div class="input-group-text bg-light">
														Rp
													</div>	
												</div>
												<input type="text" v-model.trim="amount_paid" class="form-control" placeholder="Masukan jumlah pembayaran di sini!" v-formatIDR required />
											</div>
											<small><i class="fa fa-info-circle ml-2"></i> Nominal pembayaran harus sama dengan <span class="text-success">{{ amount_paid_confirm | formatCurrency('IDR') }}.</span></small>
										</div>
										<div v-if="!image_show">
											<label class="mb-0">Unggah Bukti Bayar</label>
											<div class="custom-file">
												<input type="file" @change="onFileChange" class="custom-file-input" id="customFile" accept="image/*" required>
												<label class="custom-file-label" for="customFile">Pilih Bukti Gambar</label>
											</div>
										</div>
										<div class="text-center rounded" style="overflow: hidden;" v-else>
											<div>
												<img v-lazy="image_show" style="width: 80%;" />
											</div>
											<button class="btn btn-secondary w-75 rounded-0" @click="removeImage">Ubah Bukti Gambar</button>
										</div>
									</div>
								</div>
								<div class="modal-footer mt-3" v-if="!is_refreshDetail">
									<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
									<button type="submit" class="btn btn-primary" v-if="parseInt(amount_paid.replace(/\D/g,''))-parseInt(amount_paid_confirm) == 0">Kirim</button>
									<button type="button" class="btn btn-primary disabled" v-else>Kirim</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<!-- Modal pesanan dibatalkan -->
				<div class="modal fade" id="modal-pesanan-dibatalkan" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLongTitle">Batalkan Pesanan</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<form @submit.prevent="act_pesananDibatalkan">
								<div class="modal-body px-4">
									<div class="text-center my-3" v-if="is_refreshDetail">
										<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</div>
									<div class="container px-4 text-muted text-center" v-else>
										<p class="my-2">Apakah Anda yakin ingin membatalkan pesanan dengan Nomor Invoice <strong>{{ invoice_number }}</strong>?</p>
										<input type="hidden" v-model.trim="invoice_number" required readonly/>
									</div>
								</div>
								<div class="modal-footer mt-3" v-if="!is_refreshDetail">
									<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
									<button type="submit" class="btn btn-primary">Ya, batalkan</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<!-- Modal pesanan diterima -->
				<div class="modal fade" id="modal-pesanan-diterima" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLongTitle">Pesanan Telah Diterima</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<form @submit.prevent="act_pesananDiterima">
								<div class="modal-body px-4">
									<div class="text-center my-3" v-if="is_refreshDetail">
										<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</div>
									<div class="container px-4 text-muted text-center" v-else>
										<p class="my-2">Apakah Anda yakin telah menerima pesanan dan ingin mengakhiri transaksi dengan Nomor Invoice <strong>{{ invoice_number }}</strong>?</p>
										<input type="hidden" v-model.trim="invoice_number" required readonly/>
									</div>
								</div>
								<div class="modal-footer mt-3" v-if="!is_refreshDetail">
									<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
									<button type="submit" class="btn btn-primary">Ya, selesai</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'MenuPurchase',
	data() {
		return {
			transaksi: [],
			jumlah_transaksi: 0,
			jumlah_transaksi_count: [],
			isRefresh: false,
			is_refreshDetail: false,
			detail_barang_transaksi: [],
			detail_info_transaksi: [],
			status_transaksi: '',
			invoice_number: '',
			payment_proof: '',
			amount_paid: '',
			amount_paid_confirm: '',
			trans_id: '',
			image_show: '',
			current_page: 1,
			per_page: 10,
		}
	},
	methods: {
		to_topPage() {
			$('html, body').stop().animate({
				scrollTop: 83
			}, 1000, 'easeInOutExpo')
		},
		count_transactionList() {
			for(let i=1; i<=6; i++) {
				window.axios.get('/transaction/count?status='+ i, {
						headers: {
							"token": this.$store.getters.token_member
						}
					}
				)
				.then(response => {
					this.jumlah_transaksi_count[i] = response.data.data.count_transaction
				})
				.catch(error => {
					console.log(error)
				});
			}
		},
		get_transactionList(id) {
			let temp = []
			this.transaksi = []
			this.jumlah_transaksi = 0
			this.isRefresh = true
			window.axios.get('/transaction?status='+ id, {
       		        headers: {
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				temp = response.data.data
				this.jumlah_transaksi = temp.length

				// get product image
				if(this.jumlah_transaksi>0) {
					for(let i=0; i<this.jumlah_transaksi; i++) {
						let array_list = []
						window.axios.get('/transaction/'+ temp[i].trans_id, {
								headers: {
									"token": this.$store.getters.token_member
								}
							}
						)
						.then(response => {
							let data = response.data.data.detail_transaction
							for(let j=0; j<data.length; j++) {
								array_list.push(data[j].prod_image)
							}
						})
						.catch(error => {
							console.log(error)
						});
						temp[i]['images'] = array_list
					}
				}
				this.transaksi = temp
				this.isRefresh = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		get_detailTransaction(id, status) {
			this.is_refreshDetail = true
			window.axios.get('/transaction/'+ id, {
					headers: {
						"token": this.$store.getters.token_member
					}
				}
			)
			.then(response => {
				this.detail_barang_transaksi = response.data.data.detail_transaction
				this.detail_info_transaksi = response.data.data.transaction
				this.status_transaksi = status
				this.is_refreshDetail = false
			})
			.catch(error => {
				console.log(error)
			});
		},
		onFileChange(e) {
			this.payment_proof = e.target.files[0]
			let files = e.target.files || e.dataTransfer.files
			if (!files.length) {
				return;
			}
			this.createImage(files[0]);
		},
		createImage(file) {
			let reader = new FileReader()

			reader.onload = (e) => {
				this.image_show = e.target.result
			}
			reader.readAsDataURL(file)
		},
		removeImage: function (e) {
			this.image_show = ''
		},
		prepare_uploadBukti(inv, price) {
			this.is_refreshDetail = true
			this.invoice_number = inv
			this.payment_proof = ''
			this.image_show = ''
			this.amount_paid = ''
			this.amount_paid_confirm = price

			$('#modal-upload-bukti').modal()
			this.is_refreshDetail = false
		},
		upload_buktiBayar() {
			let formData = new FormData()
			formData.append('_method', 'patch')
			formData.append('invoice_number', this.invoice_number)
			formData.append('amount_paid', this.amount_paid.replace(/\D/g,''))
			formData.append('payment_proof', this.payment_proof)

			window.axios.post('/transaction/confirmation', formData, {
					headers: {
						// "Content-Type": "application/x-www-form-urlencoded",
						"Content-Type": "multipart/form-data",
						"token": this.$store.getters.token_member
					}
				}
			)
			.then((response) => {
				this.count_transactionList()
				this.get_transactionList(1)
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Konfirmasi pembayaran kami terima, menunggu persetujuan Admin.</p>',
					icon: 'success',
					timer: 4000,
					showConfirmButton: false,
				});
			})
			.catch((error) => {
				this.get_transactionList(1)
				this.$swal({
					title: 'Oops!', 
					html: '<p>Terjadi Kesalahan</p>',
					icon: 'error',
					timer: 2500,
					showConfirmButton: false,
				});
			})
			$('#modal-upload-bukti').modal('hide')
		},
		prepare_pesananDibatalkan(inv) {
			this.is_refreshDetail = true
			this.invoice_number = inv

			$('#modal-pesanan-dibatalkan').modal()
			this.is_refreshDetail = false
		},
		act_pesananDibatalkan() {
			let formData = new FormData()
			formData.append('_method', 'patch')
			formData.append('trans_invoice', this.invoice_number)

			window.axios.post('/transaction/batalkan-pesanan', formData, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"token": this.$store.getters.token_member
					}
				}
			)
			.then((response) => {
				this.count_transactionList()
				this.get_transactionList(1)
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Pesanan berhasil dibatalkan.</p>',
					icon: 'success',
					timer: 4000,
					showConfirmButton: false,
				});
			})
			.catch((error) => {
				this.get_transactionList(1)
				this.$swal({
					title: 'Oops!', 
					html: '<p>Terjadi Kesalahan</p>',
					icon: 'error',
					timer: 2500,
					showConfirmButton: false,
				});
			})
			$('#modal-pesanan-dibatalkan').modal('hide')
		},
		prepare_pesananDiterima(inv) {
			this.is_refreshDetail = true
			this.invoice_number = inv

			$('#modal-pesanan-diterima').modal()
			this.is_refreshDetail = false
		},
		act_pesananDiterima() {
			let formData = new FormData()
			formData.append('_method', 'patch')
			formData.append('trans_invoice', this.invoice_number)

			window.axios.post('/transaction/pesanan-diterima', formData, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"token": this.$store.getters.token_member
					}
				}
			)
			.then((response) => {
				this.count_transactionList()
				this.get_transactionList(4)
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Pesanan telah diselesaikan,</br>Terima kasih telah berbelanja!.</p>',
					icon: 'success',
					timer: 4000,
					showConfirmButton: false,
				});
			})
			.catch((error) => {
				this.get_transactionList(4)
				this.$swal({
					title: 'Oops!', 
					html: '<p>Terjadi Kesalahan</p>',
					icon: 'error',
					timer: 2500,
					showConfirmButton: false,
				});
			})
			$('#modal-pesanan-diterima').modal('hide')
		},
	},
	computed: {
		list_transaksi : function() {
			let position = $(document).scrollTop()
			$('html, body').stop().animate({
				scrollTop: 83
			}, 1000, 'easeInOutExpo')

			return this.transaksi.slice(
				(this.current_page - 1) * this.per_page, this.current_page * this.per_page,
			)
		},
      	account_type(){
			if(this.$store.getters.account_type) {
				return this.$store.getters.account_type
			}
			return localStorage.getItem('account_type')
      	},
	},
	created() {
		$(document).scrollTop(0)
		this.count_transactionList()
		this.get_transactionList(1)
		
		Vue.nextTick(function(){
			// Javascript to enable link to tab
			let hash = location.hash.replace(/^#/, '');  // ^ means starting, meaning only match the first hash

			if (hash) {
				$('.nav-item a[href="#' + hash + '"]').tab('show');
			} 

			// Change hash for page-reload
			$('.nav-item a').on('shown.bs.tab', function (e) {
				window.location.hash = e.target.hash;
			})
    	});
	},
}

</script>