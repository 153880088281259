<template>
    <div>   
		<ul class="list-group list-group-flush">
			<li class="list-group-item px-0">
				<div class="w-100 text-left d-lg-flex align-items-center justify-content-center">
					<div class="text-center">
					
					<img v-lazy="profileImage(userPhoto)" style="width: 75px; height: 75px; overflow: hidden;" :alt="userPhoto" class="rounded-circle shadow border" @error="imageLoadError" />
					
					</div>
					<div class="pl-3 text-muted">
						<h5 class="m-0">{{ userFullname | str_limit(25) | capitalize }}</h5>
						<p class="m-0">{{ account_type | uppercase }}</p>
					</div>
				</div>
			</li>
			<li class="list-group-item">
				<router-link to="/member/profile" class="product-category border-0 d-flex justify-content-between align-items-center" :class="{'text-primary': pathNow == 'profile'}"><span><i class="fa fa-user-circle"></i> &nbsp; Profil Pengguna</span></router-link>
			</li>
			<!-- <li class="list-group-item">
				<router-link to="/member/purchase" class="product-category border-0 d-flex justify-content-between align-items-center" :class="{'text-primary': pathNow == 'purchase'}"><span><i class="fa fa-cart-arrow-down"></i> &nbsp; Transaksi</span>
					<span class="badge badge-pill badge-info badge-lg" v-if="jumlah_transaksi > 0">{{ jumlah_transaksi }}</span>
				</router-link>
			</li> -->
			<!-- <li class="list-group-item">
				<router-link to="/member/bargaining" class="product-category border-0 d-flex justify-content-between align-items-center" :class="{'text-primary': pathNow == 'bargaining'}"><span><i class="fa fa-sort-amount-down"></i> &nbsp; Nego Harga</span>
					<span class="badge badge-pill badge-info badge-lg" v-if="jumlah_nego > 0">{{ jumlah_nego }}</span>
				</router-link>
			</li> -->
			<!-- <li class="list-group-item">
				<a href="pengadaan.php" class="product-category border-0 d-flex justify-content-between align-items-center"><span><i class="far fa-file-alt"></i> &nbsp; Pengadaan Barang</span></a>
			</li> -->
			<!-- <li class="list-group-item">
				<router-link to="/member/review" class="product-category border-0 d-flex justify-content-between align-items-center" :class="{'text-primary': pathNow == 'review'}"><span><i class="fa fa-pencil-alt"></i> &nbsp; Ulasan</span></router-link>
			</li> -->
			<li class="list-group-item text-center">
				<button class="btn btn btn-outline-warning px-4 my-2" @click="logout"><i class="fa fa-power-off"></i> Keluar</button>	
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'MenuMember',
	data() {
		return {
			jumlah_transaksi: 0,
			jumlah_nego: 0,
			userFullname: '',
			userPhoto: '',
		}
	},
    computed: {
		isLoading() {
			return this.$store.getters.isLoading
      	},
      	isLoggedIn : function(){
      	  	return this.$store.getters.isLoggedIn
      	},
      	// account_type : function(){
		//	if(this.$store.getters.account_type) {
		//		return this.$store.getters.account_type
		//	}
		//	return localStorage.getItem('account_type')
      	//},
      	// userFullname : function() {
		// 	if(this.$store.getters.name_member) {
		// 		return this.$store.getters.name_member
		// 	}
		// 	return localStorage.getItem('name_member')
		// },
      	// userPhoto : function() {
		// 	if(this.$store.getters.photo_profile) {
		// 		return this.$store.getters.photo_profile
		// 	}
		// 	return localStorage.getItem('photo_profile')
		// },
		pathNow() {
			return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1)
		}
	},
	methods: {
		// get_transactionCounts() {
		// 	window.axios.get('/transaction', {
       	// 	        headers: {
       	// 	            "token": this.$store.getters.token_member
       	// 	        }
       	// 	    }
       	// 	)
       	// 	.then(response => {
		// 		this.jumlah_transaksi = response.data.data.length
       	// 	})
       	// 	.catch(error => {
       	// 		console.log(error)
		// 	});
		// },
		// get_negoCounts() {
		// 	window.axios.get('/nego', {
       	// 	        headers: {
       	// 	            "token": this.$store.getters.token_member
       	// 	        }
       	// 	    }
       	// 	)
       	// 	.then(response => {
		// 		this.jumlah_nego = response.data.data.length
       	// 	})
       	// 	.catch(error => {
       	// 		console.log(error)
		// 	});
		// },
		get_userComponentData() {
			// GET NAMA MEMBER
			if(this.$store.getters.name_member) {
				this.userFullname = this.$store.getters.name_member
			}
			else {
				this.userFullname = localStorage.getItem('name_member')
			}

			// GET FOTO PROFIL MEMBER
			if(this.$store.getters.photo_profile) {
				this.userPhoto = this.$store.getters.photo_profile
			}
			else {
				this.userPhoto = localStorage.getItem('photo_profile')
			}

			// GET ACCOUNT TYPE
			if(this.$store.getters.account_type) {
				this.account_type = this.$store.getters.account_type
			}
			else {
				this.account_type = localStorage.getItem('account_type')
			}
		},
      	logout: function () {
      	  	this.$store.dispatch('logout')
      	  	.then(() => {
      	  	  	this.$router.push('/login')
			})
			.catch(error => console.log(error))
      	},
	},
	created() {
		if(this.isLoggedIn) {
			$(document).scrollTop(0)
			this.get_userComponentData()
			// this.get_transactionCounts()
			// this.get_negoCounts()
			
			window.MenuMemberDirtyAccess = this
		}
		else {
			this.$store.dispatch('logout')
		}
	}
}

</script>