<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item text-secondary">
										<router-link to="/member/profile" class=" text-decoration-none">Member</router-link>
									</li>
									<li class="breadcrumb-item active" aria-current="page">Transaksi Pembelian</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>

				<!-- Profil Pengguna -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body p-4">
							<div class="row">
								<div class="col-md-3">
									<MenuMember />
								</div>
								<div class="col-md-9">
									<MenuPurchase />
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </main>
    </div>
</template>

<script>
import MenuMember from '@/components/MenuMember.vue'
import MenuPurchase from '@/components/MenuPurchase.vue'

export default {
	components: {
  		MenuMember, MenuPurchase
  	},
}
</script>